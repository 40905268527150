import React from 'react';
import CookieBanner from 'react-cookie-banner';

const styles = {
  banner: {
    height: 57,
    background: 'rgba(52, 64, 81, 0.88)',
    backgroundSize: '30px 30px',
    backgroundColor: '',
    fontSize: '15px',
    fontWeight: 600,
    position: 'fixed',
    bottom: 0
  },
  button: {
    border: '1px solid white',
    borderRadius: 4,
    width: 66,
    height: 32,
    lineHeight: '32px',
    background: 'transparent',
    color: 'white',
    fontSize: '14px',
    fontWeight: 600,
    opacity: 1,
    right: 20,
    marginTop: -18
  },
  message: {
    display: 'block',
    padding: '9px 67px',
    lineHeight: 1.3,
    marginRight: 244,
    color: 'white'
  },
  link: {
    textDecoration: 'none',
    fontWeight: 'bold',
    border: '1px solid white',
    background: 'transparent',
    color: 'white',
    borderRadius: '10px',
    paddingLeft: '10px',
    paddingRight: '10px'
  }
};

const message = 'We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.';

export default function CookieBannerComponent() {
  return (
    <CookieBanner
      styles={styles}
      message={message}
      link={<button onClick={() => window.location = '/cookie-policy'}>More information on our use of cookies</button>}
      buttonMessage='Accept'
      dismissOnScroll={false}
      dismissOnClick={false}
    />
  );
}