import React, { createContext, useMemo, useCallback } from 'react';

import { useFetchLanguages } from './api';

const LocalizationStore = createContext();

function LocalizationProvider({ children }) {
  const [{ response: languages, loading, error }] = useFetchLanguages();

  const localization = useMemo(() => {
    return ({
      resources: {}, // will be set up and used when UI localization is needed
      languages: (!!languages && !loading && !error)
        ? languages
        : null
    });
  }, [languages, loading, error]);

  const getLanguageByCode = useCallback((code) => {
    return (!!localization && !!localization.languages)
      ? localization.languages.find(l => l.code === code)
      : null
  }, [localization]);

  const value = ({ localization, getLanguageByCode })

  return (
    <LocalizationStore.Provider value={value}>
      {children}
    </LocalizationStore.Provider>
  );
}

export {
  LocalizationStore,
  LocalizationProvider
};