import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const ShrinkFrames = keyframes`
  to { 
    transform: scale(0.98);
    opacity: 0.03;
  }
`;

const ShrinkAnimation = () => css`
  ${ShrinkFrames} 0.7s ease-in-out infinite alternate;
`;

const BoxAnimated = styled.div`
  animation: ${ShrinkAnimation};
  opacity: 0.01 !important;
`;


export default function PageLoadingPlaceholder() {
  return (
    <div className="container-fluid d-flex flex-column vh-100" style={{ overflow: 'hidden' }}>
      <div className="row flex-grow-0 mb-5">
        <div className="col-12 mb-4">
          <BoxAnimated className="alert alert-dark h-100" style={{ minHeight: '100px' }} />
        </div>
        <div className="col-12">
          <BoxAnimated className="alert alert-dark h-100" style={{ minHeight: '30px' }} />
        </div>
      </div>
      <div className="row d-flex flex-column flex-md-row flex-fill mb-5">
        <div className="col-md-4 h-100 w-100">
          <BoxAnimated className="alert alert-dark h-100" />
        </div>
        <div className="col-md-8 w-100">
          <BoxAnimated className="alert alert-dark h-100" />
        </div>
      </div>
    </div>
  )
}