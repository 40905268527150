import React from 'react';

export default function NotAuthorizedPage() {
  return (
    <div className="container d-flex flex-column">
      <div className="row align-items-center justify-content-center no-gutters min-vh-100">
        <div className="col-8 col-md-6 col-lg-7 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11">

          <img src="/assets/img/illustrations/illustration-1.png" alt="..." className="img-fluid" />

        </div>
        <div className="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11">

          <h1 className="display-3 font-weight-bold text-center">
            403 Uh Oh.
          </h1>

          <p className="mb-5 text-center text-muted">
            You have not enough permissions to view this page.
          </p>

        </div>
      </div>
    </div>
  );
}