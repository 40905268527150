import React from 'react';
import styled from 'styled-components';

const placements = {
  'top-left': { top: 0, left: 0 },
  'top-center': { top: 0, left: '50%', transform: 'translateX(-50%)' },
  'top-right': { top: 0, right: 0 },
  'bottom-left': { bottom: 0, left: 0 },
  'bottom-center': { bottom: 0, left: '50%', transform: 'translateX(-50%)' },
  'bottom-right': { bottom: 0, right: 0 },
};

const ToastContainerStyled = styled.div`
  box-sizing: border-box;
  max-height: 100%;
  overflow: hidden;
  pointer-events: ${ /* sc-value*/ ({ hasToasts }) => hasToasts ? null : 'none'};
  position: fixed;
  z-index: 3000;
  ${ ({ placement }) => ({ ...placements[placement] })};
`;

export default function ToastContainer({ hasToasts, placement, ...props }) {
  return (
    <ToastContainerStyled
      hasToasts={hasToasts}
      placement={placement}
      className="react-toast-notifications__container p-2"
      {...props}
    />
  )
}